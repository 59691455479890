import React, { act, useEffect, useRef } from 'react';
import './ActionCard.css'; // Make sure to import the CSS file
import { ResourceStats } from '../../PlayerStats';
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { ChampionCard } from '../utils/champion-card/ChampionCard';

const numberToLetter = (num) => {
  return String.fromCharCode(65 + num);
}

const positionOrient = ([x, y]) => {
  return [numberToLetter(y), 6-x];
};

const ActionCard = ({ entry }) => { 

  let new_source_position;
  if (entry.source_position) {
    new_source_position = positionOrient(entry.source_position);
  }

  let new_target_position;
  if (entry.target_position) {
    new_target_position = positionOrient(entry.target_position);
  }

  return (
  <div 
    className={`action-card`}
  >
    <div className='action-card-header'>
        {entry.name}
    </div>
    <div className='action-card-body'>
        <p>{entry.text}</p>
        {/* {entry.aide && <div style={{'scale':'.8', 'margin-left':'-40px'}}><ChampionCard buildData={entry.aide} /></div>} */}
        {/* {entry.plot && <div style={{'scale':'.8', 'margin-left':'-40px'}}><ChampionCard buildData={entry.plot} /></div>} */}
        {/* {entry.affinities && entry.affinities.length > 0 && (
          <div className='action-card-affinities'>
            {entry.affinities.map((affinity, index) => (
              <span>{`${affinity};${index == entry.affinities.length-1 ? "" : " "}`}</span>
            ))}
          </div>
        )}
        {entry.skills && entry.skills.length > 0 && (
          <div className='action-card-affinities'>
            {entry.skills.map((skill, index) => (
              <span>{`${skill};${index == entry.affinities.length-1 ? "" : " "}`}</span>
            ))}
          </div>
        )}
        {entry.cooldown > 1 && <span>{`Cooldown: ${entry.cooldown}`}</span>}
        {entry.proficiencies && Object.keys(entry.proficiencies).length > 0 && (
          <div className='action-card-affinities'>
            {Object.entries(entry.proficiencies).map(([prof, profNum], index) => (
              <span>{`[${prof}, ${profNum}];${index == Object.keys(entry.proficiencies).length-1 ? "" : " "}`}</span>
            ))}
          </div>
        )} */}
    </div>
    <div className='action-card-footer'>
        {(entry.cost || entry.cost_preview) && (<div className='action-card-cost'>
          <ResourceStats resources={!!entry.cost ? entry.cost : entry.cost_preview} />
        </div>)}
        {
        !!entry.source_position &&
        (!entry.target_position ? (
        <div className='action-card-position'>
            <span>{`${new_source_position[0]}${new_source_position[1]}`}</span>
        </div> ):(
        <div className='action-card-position'>
            <span>{`${new_source_position[0]}${new_source_position[1]}  `}</span>
            <FaRegArrowAltCircleRight/ >
            <span>{`  ${new_target_position[0]}${new_target_position[1]}`}</span>
        </div>
        ))}
    </div>
  </div>
)};

export default ActionCard;