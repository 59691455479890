import { useState } from "react";
import PieceCard, { PieceCardHeader } from "../../game/pieces/cards/piece-card/piece-card";
import { getPiece } from "../../game/pieces/champions";

import './build-tab.css';


const BuildTab = ({ draftState, socket, username }) => {
    const [selectedActionIndex, setSelectedActionIndex] = useState(null);
    const [hoveredAction, setHoveredAction] = useState(null);
    let { draft_id, phase, builds, current_cards, current_player, game_id, build_size } = draftState.state;
    let { champions, player_id, actions } = builds[username];
    let champion = champions[0];

    const updateChampion = (index) => {
        socket.current.emit('update_build', { index_1: 0, index_2: index, draft_id, card_type: 'champions' });
    }
    
    const isBuildClickFn = (action, index) => {
        if (actions && actions.length > build_size) {
            return () => setSelectedActionIndex(index);
        } else {
            return () => {};
        }
    }

    const updateBuild = (index1, index2) => {
        if (index1 === null) { return; }
        socket.current.emit('update_build', { index_1: index1, index_2: index2, draft_id, card_type: 'actions' });
        setSelectedActionIndex(null);
    }

    return (
    <div className='draft-page-build'>
        <div className='draft-champion'>
            {champion && (hoveredAction ? <PieceCard buildData={hoveredAction} /> : <PieceCard buildData={getPiece(champion)} />)}
            <br />
            {champions.slice(1).map((_champion, index) => 
            <div 
            onClick={()=>updateChampion(index+1)}
            onMouseEnter={()=>setHoveredAction(_champion)}
            onMouseLeave={()=>setHoveredAction(null)}
            >
                <PieceCardHeader buildData={getPiece(_champion)} />
            </div>
            )}
        </div>
        <div className="draft-actions">
            {actions && actions.slice(0, build_size).map((action, index) => (
                <div className={`card ${index == selectedActionIndex ? 'selected' : 'not-selected'}`} 
                    onClick={isBuildClickFn(action, index)}
                    onMouseEnter={()=>setHoveredAction(action)}
                    onMouseLeave={()=>setHoveredAction(null)}
                >
                    <PieceCardHeader buildData={getPiece(action)} />
                </div>
            ))}
        </div>
        <div className="draft-actions-backup">
            {actions && actions.slice(build_size).map((action, index) => (
                <div className='card' 
                onClick={()=>updateBuild(selectedActionIndex, index+build_size)}
                onMouseEnter={()=>setHoveredAction(action)}
                onMouseLeave={()=>setHoveredAction(null)}
                >
                    <PieceCardHeader buildData={getPiece(action)} />
                </div>
            ))}
        </div>
    </div>
    );
}

export default BuildTab;