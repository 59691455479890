import React from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate } from "react-router-dom";
import './index.css';
import App from './App';
import { Router } from 'react-router-dom';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

const domain = "dev-g2qf222u2ol8i4lz.us.auth0.com";
const clientId = "rLnJgm5gqDBTFlhkfjUvtZXBleSQCSN7";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      // redirectUri={window.location.origin}
      // onRedirectCallback={onRedirectCallback}
    >      <App/>
    </Auth0Provider>
  // {/* </React.StrictMode> */}
);

