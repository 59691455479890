import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPiece } from '../../game/pieces/champions';
import { ChampionCard } from '../../game/pieces/utils/champion-card/ChampionCard';
import PieceCard from '../../game/pieces/cards/piece-card/piece-card';
// import ActionCard from '../../game/pieces/cards/ActionCard';

import './draft-tab.css';


const DraftTab = ({ draftState, socket, username }) => {
    const navigate = useNavigate();

    const selectChampion = (champion) =>{
        return () => {
            socket.current.emit('select_champion', { draft_id: draft_id, champion_name: champion });
        };
    }

    const selectCard = (card_idx) => {
        return () => {
            socket.current.emit('select_card', { draft_id: draft_id, card_idx: card_idx });
        };
    }

    let { draft_id, phase, builds, current_cards, current_player, game_id } = draftState.state;
    let { champion_choices, selected_champion, player_id } = builds[username];

    if (phase == "Draft Done" && !!game_id) {
        navigate(`/game/${game_id}`);
    } else {
        console.log('phase, game_id', phase, game_id);
    }

    return (
        <div className='draft-page-draft'>
            {phase == "Champion Selection" &&  
                <div className={`champion-selection`}>
                    {champion_choices.map((champion, index) => getPiece(champion)).map((champion, index) => (
                        <div className={`champion-card ${ selected_champion && champion.name == selected_champion.name ? 'selected' : 'not-selected' }`} onClick={selectChampion(champion.name)}>
                            <PieceCard buildData={champion}  />
                        </div>
                ))}
                </div>
            }
            {phase == "Action Draft" && 
                <div>
                    <div className={`action-selection ${player_id == current_player ? 'your-pick':'not-your-pick'}`}>
                        {current_cards.map((card, index) => (
                            <div 
                            className='action-card' 
                            key={index}
                            onClick={selectCard(index)}
                            >
                                <PieceCard buildData={card} />
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
}

export default DraftTab;