import React from 'react';
import { FaHeart } from 'react-icons/fa'; // Example icons for health, mana, and stamina
import { GiSwordSpade, GiBrain, GiShield, GiConcentrationOrb, GiLeatherBoot } from 'react-icons/gi'; // Example icons for str, int, and def
import { RiLightbulbFlashFill } from "react-icons/ri";
import './PlayerStats.css';

// export const ResourceStats = ({ resources, style }) => {
//   return (
//     <div className="resources" style={style}>
//       <div className="stat-item"><FaHeart color='red' />{resources.health}</div>
//       <div className="stat-item"><GiConcentrationOrb color='blue' />{resources.mana}</div>
//       <div className="stat-item"><GiLeatherBoot color='brown' />{resources.stamina}</div>
//     </div>
//   )
// };

export const ResourceStats = ({ resources, style }) => {
  const renderStat = (icon, resourceType, actualType, color) => {
    const value = resources[resourceType];
    const actualValue = resources.cost_actual ? resources.cost_actual[resourceType] : null;
    return (
      <div className={`stat-item cost ${(actualValue != null && actualValue != value) ? 'highlight' : ''}`}>
        {icon}
        <span style={{color: 'black'}}>{value}</span>
        {(actualValue != null && actualValue != value) && (
          <span className="actual-value" style={{color: color}}>{` (${actualValue})`}</span>
        )}
      </div>
    );
  };

  return (
    <div className="resources" style={style}>
      {renderStat(<FaHeart color="red" />, 'health', 'cost_actual.health', 'red')}
      {renderStat(<GiConcentrationOrb color="blue" />, 'mana', 'cost_actual.mana', 'blue')}
      {renderStat(<GiLeatherBoot color="brown" />, 'stamina', 'cost_actual.stamina', 'brown')}
    </div>
  );
};

export const ChampionStat = ({ player }) => {
  return (
    <>
    {(player.champion.health || player.champion.mana || player.champion.stamina) && <div className="player-resources">
      {player.champion.health && <div className="stat-item"><FaHeart color='red' />{`${player.champion.health[0]} / ${player.champion.health[1]}`}</div>}
      {player.champion.mana && <div className="stat-item"><GiConcentrationOrb color='blue' />{`${player.champion.mana[0]} / ${player.champion.mana[1]}`}</div>}
      {player.champion.stamina && <div className="stat-item"><GiLeatherBoot color='brown' />{`${player.champion.stamina[0]} / ${player.champion.stamina[1]}`}</div>}
    </div>}
    {(player.champion.strength || player.champion.strength || player.champion.intelligence) && <div className="player-stats">
      {player.champion.strength && <div className="stat-item"><GiSwordSpade />{player.champion.strength}</div>}
      {player.champion.intelligence && <div className="stat-item"><RiLightbulbFlashFill />{player.champion.intelligence}</div>}
      {player.champion.defense && <div className="stat-item"><GiShield />{player.champion.defense}</div>}
    </div>}
  </>
  )
};


const PlayerStats = ({ players, playerId }) => {
  const _players = playerId == 1 ? players : players.slice().reverse();
  return (
    <div className="player-stats-panel">
      {_players.map((player, playerIndex) => (
        <div key={player.id} className={`player-card ${playerIndex==1 ? "me" : "you"}`}>
          <h3>{player.name}</h3>
          {/* <h3>{playerIndex == 1 ? "You" : "Opponent"}</h3> */}
          <div className="player-columns">
            <ChampionStat player={player} />
            {/* <div className="player-resources">
              <div className="stat-item"><FaHeart color='red' />{player.champion.health[0]}</div>
              <div className="stat-item"><GiConcentrationOrb color='blue' />{player.champion.mana[0]}</div>
              <div className="stat-item"><GiLeatherBoot color='brown' />{player.champion.stamina[0]}</div>
            </div> */}
            {/* <div className="player-stats">
              <div className="stat-item"><GiSwordSpade />{player.champion.strength}</div>
              <div className="stat-item"><RiLightbulbFlashFill />{player.champion.intelligence}</div>
              <div className="stat-item"><GiShield />{player.champion.defense}</div>
            </div> */}
            <div className="player-proficiencies">
              {player.champion.proficiencies && Object.entries(player.champion.proficiencies).map(([prof, value]) => (
                <span>{`${prof}: ${value}`}</span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlayerStats;
