import React from 'react';

const FullscreenButton = () => {
  const handleFullscreen = () => {
    const element = document.documentElement; // This targets the entire document

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { // For Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { // For Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { // For IE/Edge
      element.msRequestFullscreen();
    }
  };

  return (
    <button onClick={handleFullscreen}>
      Go Fullscreen
    </button>
  );
};

export default FullscreenButton;