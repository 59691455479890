import React, { act, useEffect, useRef } from 'react';
import './LogPanel.css'; // Make sure to import the CSS file
import { ResourceStats } from '../PlayerStats';
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import ActionCard from '../pieces/cards/ActionCard';
import PieceCard from '../pieces/cards/piece-card/piece-card';


const LogPanel = ({ log, action }) => {
  const logEndRef = useRef(null);

  useEffect(() => {
    if (logEndRef.current) {
      logEndRef.current.scrollIntoView({ behavior: 'instant' });
    }
  }, [log, action]);

  return (
    <div className='log-panel'>
      {!action ? 
      // {true ?
        log.map((entry, index) => (
          <div key={index} style={{}}>
            <ActionCard entry={entry} key={index}/>
          </div>
        ))
        : <ActionCard entry={action} />}
      <div ref={logEndRef} />
    </div>
  );
};

export default LogPanel;
