export const Race = {
    ELF: 'Elf',
    BEEBO: 'Beebo',
    ANGEL: 'Angel',
};

export const Cls = {
    SHAMAN: 'Shaman',
    TEMPORAL_MAGE: 'Temporal Mage',
    SOUL_MAGE: 'Soul Mage',
};

export const Phases = {
    START: 'Start',
    ACTION: 'Action',
    REACTION: 'Reaction',
    COMPLETE: 'Complete',
    END: 'End',
  };
  
export const Players = {
    BLUE: 'blue',
    RED: 'red',
};
  