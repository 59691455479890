import React from 'react';
import { useState, useEffect } from 'react';
import './game-over-screen.css'; // Make sure to create and style this CSS file

const winOrLostText = (win) => {
  if (win) {
    return (
      <div className="game-over-text win">
        you win<br />
        they lose<br />
        they suck<br />
        you dont
      </div>
    );
  } else {
    return (
      <div className="game-over-text lose">
        you lose<br />
        they win<br />
        they are better<br />
        you suck
      </div>
    );
  }
};


const GameOverScreen = ({ win, show }) => {
  const [showScreen, setShowScreen] = useState(false);

  useEffect(() => {
    if (show) {
      setShowScreen(true);
    }
  }, [show]);

  if (!showScreen) return null;

  return (
    <div className={`game-over-screen ${win ? 'win' : 'lose'}`}>
      {winOrLostText(win)}
    </div>
  );
};

export default GameOverScreen;
