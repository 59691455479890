import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

import { getUserBuilds } from "../../utils/getters";
import './user-builds-page.css';
import Navbar from "../../common-components/navbar/navbar";

const convertRowtoObject = (row) => {
    const [build_name, champion_name, action_0, action_1, action_2, action_3, action_4, action_5, action_6, action_7] = row;
    console.log('row', {
        name: build_name,
        champion: champion_name,
        actions: [action_0, action_1, action_2, action_3, action_4, action_5, action_6, action_7]
    });
    return {
        name: build_name,
        champion: champion_name,
        actions: [action_0, action_1, action_2, action_3, action_4, action_5, action_6, action_7]
    }
}

const UserBuildsPage = () => {
    const [builds, setBuilds] = useState([]);
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const setBuildsAtMount = async () => {
            const token = await getAccessTokenSilently();
            // getUserBuilds(token).then(data => setBuilds(data.slice(1).map(convertRowtoObject)));
            getUserBuilds(token).then(data => setBuilds(data));
        };
        setBuildsAtMount();
    }, []);

    console.log('builds GET user', builds);
  
    return (
    <div className="user-builds-page-container">
        <Navbar />
        <div className="user-builds-page">
            <h1>Builds</h1>
            <div className='user-builds'>
            {builds && builds.map((build, index) => 
                <div 
                    className='user-build'
                    key={index}
                    onDoubleClick={() => 
                        navigate(`/build_maker`, { state: { input: build } })
                    }
                >
                    {build.build_name}
                </div>
            )}
            </div>
        </div>
    </div>
  );
}

export default UserBuildsPage;