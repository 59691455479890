import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./ChampionPage.css";

import { getPiece } from "../../pieces/champions";
import PieceCard from "../../pieces/cards/piece-card/piece-card";
import { getAllBuilds, getUserBuilds } from "../../../utils/getters";


// Define the base URL of the API
let backend;
if (process.env.REACT_APP_DEV) {
    backend = 'http://127.0.0.1:5000'
} else {
    backend = 'https://api.solbrew.io'
}
// Function to call the get_action API
// async function getAllBuilds() {
//     try {
//         const url = `${backend}/get_all_builds`;
//         const response = await fetch(url, {
//             method: 'GET',
//         });
//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const data = await response.json();
//         return data;

//     } catch (error) {
//         console.error('Error fetching action:', error);
//         return { error: error.message };
//     }
// };

const CopyUrlButton = () => {
    const copyUrlToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(window.location.href);
        alert('URL copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy URL: ', err);
      }
    };
  
    return (
      <button onClick={copyUrlToClipboard}>
        Copy Game Link to Clipboard
      </button>
    );
  };


const ChampionPage = ({ championId, setChampionId, isGame }) => {
    const [prebuilds, setPrebuilds] = useState([]);
    const [userBuilds, setUserBuilds] = useState([]);
    const [custom, setCustom] = useState(false);
    let { getAccessTokenSilently } = useAuth0();
    
    useEffect(() => {
        getAllBuilds().then(data => {
            if (data.error) {
                console.error('Error:', data.error);
            } else {
                console.log('Success:', data);
                setPrebuilds(()=>data);
            }
        });

        if (isGame) {
            const _getUserBuilds = async () => {
                const token = await getAccessTokenSilently();
                getUserBuilds(token).then(data => {
                    if (data.error) {
                        console.error('Error:', data.error);
                    } else {
                        console.log('Success:', data);
                        setUserBuilds(()=>data);
                    }
                });
            }
            _getUserBuilds();
        }
    }, []);

    let builds = (isGame && custom) ? userBuilds: prebuilds;

    return (
        <div>
        {isGame && <div className="build-buttons">
            <button onClick={()=>{
                setChampionId(null);
                setCustom(false);
            }}>Prebuilds</button>
            <button onClick={()=>{
                setChampionId(null);
                setCustom(true);
            }}>Custom builds</button>
        </div>}
        <div className={`champion-page ${custom ? 'custom' : ''}`}>
            {!custom && builds.map((build, bId) => 
                <div
                    className={`champion-card ${championId === bId ? 'selected' : ''}`}
                    onClick={isGame ? ()=>{
                        console.log('Clicked on build', bId);
                        setChampionId(bId)} : ()=>{}}
                    key={bId}
                >
                    <PieceCard buildData={getPiece(build)} />
                </div>)}
            {custom && builds.map((build, bId) => 
            <div
                className={`champion-card ${championId === build.build_name ? 'selected' : ''}`}
                onClick={isGame ? ()=>{
                    console.log('Clicked on build', bId);
                    setChampionId(build.build_name)} : ()=>{}}
                key={bId}
            >
                <div 
                    className='user-build'
                    key={bId}
                >
                    {build.build_name}
                </div>
            </div>)}
        </div>
        {isGame && <div className="copy-url-button">
            <CopyUrlButton />
        </div>}
        </div>

    );
}

export default ChampionPage;