import Piece from './piece';
import { Race, Cls } from '../types';
import { Move } from '../actions/action';

import greatOak from '../../assets/GREATOAK.png';
import quickSand from '../../assets/QUICKSAND.png';
import bloodBath from '../../assets/BLOODBATH.png';
import mudWall from '../../assets/MUDWALL.png';
import goose from '../../assets/GOOSE.png';

import suvira from '../../assets/SUVIRA_V2.png';
import gillian from '../../assets/GILLIAN.png';
import rendigols from '../../assets/RENDIGOLS.png';
import lowLevelDemon from '../../assets/LVL1_DEMON_SUMMON.png';
import midLevelDemon from '../../assets/LVL2_DEMON_SUMMON.png';
import forestFriend from '../../assets/FOREST_FRIEND.png';
import smallGolem from '../../assets/SMALL_GOLEM.png';
import thornyBushes from '../../assets/THORNY_BUSHES.png';
import magmaPit from '../../assets/MAGMA_PIT.png';
import pierre from '../../assets/PIERRE.png';
import eruptingVolcano from '../../assets/ERUPTING_VOLCANO.png';
import lava from '../../assets/LAVA.png';
import megaMole from '../../assets/MEGA_MOLE.png';
import moleHoles from '../../assets/MOLE_HOLES.png';
import phoenixEgg from '../../assets/PHOENIX_EGG.png';
import phoenix from '../../assets/PHOENIX.png';
import babyPhoenix from '../../assets/BABY_PHOENIX.png';
import forest from '../../assets/FOREST.png';
import statueOfBerkir from '../../assets/STATUE_OF_BERKIR.png';
import tRexEgg from '../../assets/TREX_EGG.png';
import tRex from '../../assets/TREX.png';
import skeletonSoldier from '../../assets/WEAK_SKELETON.png';
import necropolis from '../../assets/NECROPOLIS.png';
import youngMoltenSpiritBeast from '../../assets/YOUNG_MOLTEN_SPIRIT_BEAST.png';
import angelicSoldier from '../../assets/ANGELIC_SOLDIER.png';
import lyri from '../../assets/LYRI.png';
import elvenGuard from '../../assets/ELVEN_GUARD.png';
import elvenHonorGuard from '../../assets/ELVEN_HONOR_GUARD.png';
import archersOutpost from '../../assets/ARCHERS_OUTPOST.png';
import solbrew from '../../assets/SOLBREW.png';
import brisborealis from '../../assets/BRISBOREALIS.png';
import bloodBaby from '../../assets/BLOOD_BABY.png';
import crimsonBloodBlade from '../../assets/CRIMSON_BLOOD_BLADE.png';
import templeOfTheChosen from '../../assets/TEMPLE_OF_THE_CHOSEN.png';
import icyFloor from '../../assets/ICY_FLOOR.png';
import hawei from '../../assets/HAWEI.png';
import roboHelper from '../../assets/ROBO_HELPER.png';
import scienceLab from '../../assets/SCIENCE_LAB.png';
import frostStalker from '../../assets/FROST_STALKER.png';
import mechaFrostFang from '../../assets/MECHA_FROST_FANG.png';
import rift from '../../assets/RIFT.png';
import reyamori from '../../assets/REYAMORI.png';
import dimensionalLeopardScout from '../../assets/DIMENSIONAL_LEOPARD_SCOUT.png';
import riftDragon from '../../assets/RIFT_DRAGON.png';
import paladinSkeleton from '../../assets/PALADIN_SKELETON.png';
import riftBot from '../../assets/RIFT_BOT.png';
import fireball from '../../assets/FIREBALL.webp';
import elvenArcherApprentice from '../../assets/ELVEN_ARCHER_APPRENTICE.webp';
import megaFrostGolem from '../../assets/MEGA_FROST_GOLEM.webp';
import fatherOfTheChoosen from '../../assets/FATHER_OF_THE_CHOSEN.webp';
import weaponsTrainingGround from '../../assets/WEAPONS_TRAINING_GROUND.png';
import riftWorld from '../../assets/RIFT_WORLD.png';
import nightshadeOwl from '../../assets/NIGHTSHADE_OWL.webp';
import flicker_wisp from '../../assets/FLICKER_WISP.webp';
import mega_magma_monster from '../../assets/MEGA_MAGMA_MONSTER.webp';
import flaming_spider from '../../assets/FLAMING_SPIDER.webp';
import frozenWasteland from '../../assets/FROZEN_WASTELAND.png';
import yafa from '../../assets/YAFA.webp';
import quest from '../../assets/CONTRACT.png';
import enes from '../../assets/ENES.webp';
import wingullGuardian from '../../assets/WINGULL_GUARDIAN.webp';
import wingedLion from '../../assets/WINGED_LION.webp';
import cloud from '../../assets/CLOUD.png';
import cloudPalace from '../../assets/CLOUD_PALACE.png';
import frostFlyer from '../../assets/FROST_FLYER.webp';
import blizardMonster from '../../assets/BLIZZARD_MONSTER.webp';
import zephyron from '../../assets/ZEPHYRON.webp';
import avenCaptain from '../../assets/AVEN_CAPTAIN.webp';
import ragingStorm from '../../assets/RAGING_STORM.webp';
import abyssalHemoflux from '../../assets/ABYSSAL_HEMOFLUX.webp';
import seaScion from '../../assets/SEA_SCION.png';
import laserTurret from '../../assets/LASER_TURRET.png';
import ocean from '../../assets/OCEAN.png';
import eel from '../../assets/EEL.png';
import seahorse from '../../assets/SEAHORSE.webp';
import sildin from '../../assets/SILDIN.webp';
import royalFish from '../../assets/ROYAL_FISH.webp';
import cosmicShadowCougar from '../../assets/COSMIC_SHADOW_COUGAR.png';
import bloodFlameShaman from '../../assets/BLOOD_FLAME_SHAMAN.webp';
import runicTurtle from '../../assets/RUNIC_TURTLE.webp';
import frostBunny from '../../assets/FROST_BUNNY.webp';
import rookieVampire from '../../assets/ROOKIE_VAMPIRE.webp';
import bloodThrone from '../../assets/BLOOD_THRONE.png';
import frostReaper from '../../assets/FROST_REAPER.webp';
import cubeMonster from '../../assets/CUBE_MONSTER.webp';
import cyborgMonkey from '../../assets/CYBORG_MONKEY.webp';
import avenMech from '../../assets/AVEN_MECH.webp';
import chubbyPigeon from '../../assets/CHUBBY_PIGEON.webp';
import monumentalFluffBall from '../../assets/MONUMENTAL_FLUFF_BALL.webp';
import whirlpool from '../../assets/WHIRLPOOL.png';
import cyborgDrillMole from '../../assets/CYBORG_DRILL_MOLE.png';
import cosmicOoze from '../../assets/COSMIC_OOZE.png';


export const getPiece = (cell) => {
    if (!cell || !!cell.isCls) {
      return cell;
    }
    let piece = new Piece(cell);
    piece.isCls = true;
    if (cell.name in IMAGE_MAP) {
      piece.image = IMAGE_MAP[cell.name];
    } else if (cell.name !== 'Empty') {
      piece.image = solbrew;
    }
    return piece;
  };


  const IMAGE_MAP = {
    'Timeless Psychopath, Suvira': suvira,
    'Bearer of the goose, Gillian': gillian,
    'Goose': goose,
    'Blood Spring': bloodBath,
    'Forest Friend': forestFriend,
    'Small Golem': smallGolem,
    'Great Oak': greatOak,
    'Quick Sand': quickSand,
    'Thorny Bushes': thornyBushes,
    'Low Level Demon': lowLevelDemon,
    'Mid Level Demon': midLevelDemon,
    'Hotheaded Drunk, Rendigols': rendigols,
    'Mud Wall': mudWall,
    'Magma Pit': magmaPit,
    'Pyromaniacal Cultivator, Pierre': pierre,
    'Erupting Volcano': eruptingVolcano,
    'Lava': lava,
    'Mega Mole': megaMole,
    'Mole Holes': moleHoles,
    'Phoenix Egg': phoenixEgg,
    'Phoenix': phoenix,
    'Baby Phoenix': babyPhoenix,
    'Forest': forest,
    'Statue of Berkir': statueOfBerkir,
    'T-Rex Egg': tRexEgg,
    'T-Rex': tRex,
    'Skeleton Soldier': skeletonSoldier,
    'Necropolis': necropolis,
    'Young Molten Spirit Beast': youngMoltenSpiritBeast,
    'Angelic Soldier': angelicSoldier,
    'Forest\'s Marksman, Lyri': lyri,
    'Elven Guard': elvenGuard,
    'Elven Honor Guard': elvenHonorGuard,
    'Archer\'s Outpost': archersOutpost,
    'Seraphim of Sign, Brisborealis': brisborealis,
    'Blood Baby': bloodBaby,
    'Crimson Blood Blade': crimsonBloodBlade,
    'Temple of the Chosen': templeOfTheChosen,
    'Icy Floor': icyFloor,
    'Royal Innovator, Hawei': hawei,
    'Robo-Helper': roboHelper,
    'Science Lab': scienceLab,
    'Frost Stalker': frostStalker,
    'Mecha Frost Fang': mechaFrostFang,
    'Rift': rift,
    'Student of the Rift, Reyamori': reyamori,
    'Dimensional Leopard Scout': dimensionalLeopardScout,
    'Rift Dragon': riftDragon,
    'Paladin Skeleton': paladinSkeleton,
    'Rift Bot': riftBot,
    'Fireball': fireball,
    'Elven Archer Apprentice': elvenArcherApprentice,
    'Mega Frost Golem': megaFrostGolem,
    'Father of the Chosen': fatherOfTheChoosen,
    'Weapons Training Ground': weaponsTrainingGround,
    'Rift World': riftWorld,
    'Nightshade Owl': nightshadeOwl,
    'Flicker Wisp': flicker_wisp,
    'Mega Magma Monster': mega_magma_monster,
    'Flaming Spider': flaming_spider,
    'Frozen Wasteland': frozenWasteland,
    'Swifest End, Yafa': yafa,
    'Quest': quest,
    'Undefeated Wind, Enes': enes,
    'Wingull Guardian': wingullGuardian,
    'Winged Lion': wingedLion,
    'Cloud': cloud,
    'Cloud Palace': cloudPalace,
    'Frost Flyer': frostFlyer,
    'Blizzard Monster': blizardMonster,
    'Zephyron Steed': zephyron,
    'Aven Captain': avenCaptain,
    'Raging Storm': ragingStorm,
    'Abyssal Hemoflux': abyssalHemoflux,
    'Sea Scion': seaScion,
    'Laser Turret': laserTurret,
    'Ocean': ocean,
    'Eel': eel,
    'Seahorse': seahorse,
    'Zolini Princess, Sildin': sildin,
    'Royal Fish': royalFish,
    'Cosmic Shadow Cougar': cosmicShadowCougar,
    'Blood Flame Shaman': bloodFlameShaman,
    'Runic Turtle': runicTurtle,
    'Frost Bunny': frostBunny,
    'Rookie Vampire': rookieVampire,
    'Blood Throne': bloodThrone,
    'Frost Reaper': frostReaper,
    'Cube Monster': cubeMonster,
    'Cyborg Monkey': cyborgMonkey,
    'Aven Mech': avenMech,
    'Chubby Pigeon': chubbyPigeon,
    'Monumental Fluff Ball': monumentalFluffBall,
    'Whirlpool': whirlpool,
    'Cyborg Drill Mole': cyborgDrillMole,
    'Cosmic Ooze': cosmicOoze,
  };