import { FaHandPointer, FaHourglassHalf, FaCrosshairs } from 'react-icons/fa';
import { TiArrowRightThick } from "react-icons/ti";
import { GiLeatherBoot } from 'react-icons/gi';
import { FaBoltLightning } from "react-icons/fa6";

export const actionToCursor  = (currentAction) => {
    if (!currentAction || !currentAction.type) {
      return null;
    }
    switch (currentAction.type) {
      case 'Movement':
        return <GiLeatherBoot size={24} color='black' />;
      case 'Action':
        return <FaBoltLightning size={24} color='black' />;
    default:
        return null;
    }
};