import React from 'react';
import PlayerStats from './PlayerStats'; // Make sure the path is correct
import './GameInfo.css';

const GameInfo = ({ gameState, canAct, playerId }) => {
  let { players, turn_phase } = gameState.state;

  return (
    <div className="game-info">
      <div><strong>Phase:</strong> {turn_phase}</div>
      <div className='current-player-bar'>
        <div className={`actor-bar your-action ${canAct ? 'shown' : 'not-shown'}`}>
          Your Action!
        </div>
        <div className={`actor-bar not-your-action ${!canAct ? 'shown' : 'not-shown'}`}>
          Not Your Action!
        </div>
      </div>
      <PlayerStats players={players} playerId={playerId} />
    </div>
  );
};

export default GameInfo;
