import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import './ChampionCard.css';
import { getPiece } from '../../champions';
import { ChampionStat, ResourceStats } from '../../../PlayerStats';


let backend;
if (process.env.REACT_APP_DEV) {
    backend = 'http://127.0.0.1:5000'
} else {
    backend = 'https://api.solbrew.io'
}
// Function to call the get_action API
async function getBuild(buildId) {
    try {
        const url = `${backend}/get_build?build_id=${encodeURIComponent(buildId)}`;
        const response = await fetch(url, {
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching action:', error);
        return { error: error.message };
    }
};


export const ChampionCard = ({ buildData }) => {
    // console.log('buildData', buildData);

    let default_action = null;
    if (buildData.default_action && buildData.default_action.type != 'Movement') {
        default_action = buildData.default_action.name;
    }

  return (
    <div className="trading-card">
        <div className="trading-card-top">
            <div className="card-header">
                <h3 className="card-name">{buildData.name}</h3>
            </div>
        </div>

      {buildData.image && <img src={buildData.image} alt={`${buildData.name}`} className="zcard-image" />}

      {buildData.race && (<div className="card-details">
        <h5>Race: {buildData.race.name}</h5>
        {buildData.race.affinities.length > 0 && <h6>Affinities: {buildData.race.affinities.join(', ')}</h6>}
        {buildData.race.skills.length > 0 && <h6>Skills: {buildData.race.skills.join(', ')}</h6>}
        {buildData.race.effects && buildData.race.effects.length > 0 && (
        <div className="card-effects">
            {buildData.race.effects.map((effect, index) => (
              <h6 key={index}>{`Effect: ${effect.text}`}</h6>
            ))}
        </div>
      )}
      </div>)}

      {buildData.cls && buildData.cls.length > 0 && buildData.cls.map((cls_, index) => (
      <div key={index} className="card-details">
        <h5>Class: {cls_.name}</h5>
        {cls_.affinities.length > 0 && <h6>Affinities: {cls_.affinities.join(', ')}</h6>}
        {cls_.skills.length > 0 && <h6>Skills: {cls_.skills.join(', ')}</h6>}
        {cls_.effects && cls_.effects.length > 0 && (
        <div className="card-effects">
            {cls_.effects.map((effect, index) => (
              <h6 key={index}>{`Effect: ${effect.text}`}</h6>
            ))}
        </div>
      )}
      </div>))}

      <div className="card-details">
            {buildData.affinities && buildData.affinities.length > 0 && <h6>Affinities: {buildData.affinities.join(', ')}</h6>}
            {buildData.skills && buildData.skills.length > 0 && <h6>Skills: {buildData.skills.join(', ')}</h6>}
            {buildData.effects && buildData.effects.length > 0 && (
                <div className="card-effects">
                    {buildData.effects.map((effect, index) => (
                    <h6 key={index}>{`Effect: ${effect.text}`}</h6>
                    ))}
                </div>
            )}
    </div>
    <div className='card-details'>
        {buildData.proficiencies && Object.keys(buildData.proficiencies).length > 0 && Object.entries(buildData.proficiencies).map(([proficiency, num], index) => <h6 key={index}>{`${proficiency}:${num}`}</h6>)}
    </div>
    {default_action && <div className='card-details'><h6>Action: {default_action}</h6></div>}
    {buildData.piece_type && (<div className='champ-stats'>
        <ChampionStat player={{champion:buildData}} />
    </div>)}
    </div>
  );
};

const ChampionCardPage = () => {
    const { buildId } = useParams();
    const [buildData, setBuildData] = useState(null);

    useEffect(() => {
        console.log('buildId', buildId);
        if (!buildId) {
            return;
        }
        getBuild(buildId).then(data => {
            if (data.error) {
                console.error('Error:', data.error);
            } else {
                console.log('Success:', data);
                setBuildData(()=>getPiece(data));
            }
        });
    }, []);

    return (buildData && <ChampionCard buildData={buildData} />);
};

export default ChampionCardPage;
