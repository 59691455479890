import { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './build-actions-list.css';

import { PieceCardHeader } from '../../../game/pieces/cards/piece-card/piece-card';

const BuildActionsList = ({ championActions, buildActions, setBuildActions, setHoveredAction }) => {
    const [selectedActionIndex, setSelectedActionIndex] = useState(null);
    const maxBuildSize = 8;

    const handleRemoveAction = (index) => {
        const updatedActions = buildActions.filter((_, i) => i !== index-championActions.length);
        setBuildActions(updatedActions);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedActions = Array.from(buildActions);
        const [removed] = reorderedActions.splice(result.source.index, 1);
        reorderedActions.splice(result.destination.index, 0, removed);
        setBuildActions(reorderedActions);
    };

    let actions = [...championActions, ...buildActions];
    const emptySlots = maxBuildSize - buildActions.length;
    return (
            <div className="build-actions-list">
                <div className="action-list-container">
                    <ul className="action-list">
                    {actions.map((action, index) => (
                        <li
                            className={`action-item ${index === selectedActionIndex ? 'selected' : 'not-selected'} ${index < championActions.length ? 'champion-action' : 'build-action'}`}
                            onMouseEnter={() => setHoveredAction(action)}
                            onMouseLeave={() => setHoveredAction(null)}
                        >
                            <h4 className="action-name">{action.name}</h4>
                            <p className="action-affinities">Affinities: {action.affinities.join(', ')}</p>
                            <p className="action-skills">Skills: {action.skills.length > 0 ? action.skills.join(', ') : 'None'}</p>
                            <div className="trash-icon-container">
                                <FaTrashAlt
                                    className="trash-icon"
                                    onClick={() => {
                                        handleRemoveAction(index);
                                    }}
                                />
                            </div>
                        </li>
                    ))}
                    {emptySlots > 0 && [...Array(emptySlots)].map((_, index) => (
                        <li className="action-item empty-slot" key={index}>
                            <h4 className="action-name">Empty Slot</h4>
                        </li>
                    ))}
                    </ul>
                </div>
        </div>
    );
};

export default BuildActionsList;
