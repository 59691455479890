import "./piece-card.css";

import { getPiece } from '../../champions';
import { ChampionStat, ResourceStats } from '../../../PlayerStats';

export const PieceCardHeader = ({buildData}) => {
    let resources = !!buildData.cost ? buildData.cost : buildData.cost_preview;
    if (buildData.cost_actual) {
        resources.cost_actual = buildData.cost_actual;
    }
    return (
    <div className="trading-card-top">
        <div className="card-header">
            <h3 className="card-name">
                {buildData.name}
            </h3>
            {(buildData.cost || buildData.cost_preview) && (<div className='card-cost'>
            <div className="resource-stats">
                <ResourceStats resources={!!buildData.cost ? buildData.cost : buildData.cost_preview} />
            </div>
            </div>)}
        </div>
    </div>
    )

}

const PieceCard = ({ buildData }) => {

    let default_action = null;
    if (buildData.default_action && buildData.default_action.type != 'Movement') {
        default_action = buildData.default_action;
    }

    let piece = null;
    if (buildData.aide || buildData.plot) {
        piece = buildData.aide ? buildData.aide : buildData.plot;
        piece = getPiece(piece);
        // console.log('piece', piece);
    } else {
        buildData = getPiece(buildData);
    }

    return (
        <div className="trading-card">
            <PieceCardHeader buildData={buildData} />

            <div className='card-image-container'>
                {buildData.image && <img src={buildData.image} alt={`${buildData.name}`} className="card-image" />}
                {piece && piece.image && <img src={piece.image} alt={`${piece.name}`} className="card-image" />}
            </div>

            <div className="below-image-continer">
                <div className="card-details-container">
                    {/* <div className="text-boxes"> */}
                    <div className="main-card-details">
                        {buildData.race && (
                            <div className="card-details">
                                <h5>Race: {buildData.race.name}</h5>
                                {buildData.race.affinities.length > 0 && <h6>Affinities: {buildData.race.affinities.join(', ')}</h6>}
                                {buildData.race.skills.length > 0 && <h6>Skills: {buildData.race.skills.join(', ')}</h6>}
                                {buildData.race.effects && buildData.race.effects.length > 0 && (
                                    <div className="card-effects">
                                        {buildData.race.effects.map((effect, index) => (
                                            <h6 key={index}><span className="effect">Effect: </span>{`${effect.text}`}</h6>
                                        ))}
                                    </div>
                                )}
                                {buildData.race.actions && buildData.race.actions.length > 0 && (
                                    <h6>Race Actions: {buildData.race.actions.map(action => action.name).join(', ')}</h6>
                                )}
                            </div>
                        )}

                        {buildData.cls && buildData.cls.length > 0 && buildData.cls.map((cls_, index) => (
                            <div key={index} className="card-details">
                                <h5>Class: {cls_.name}</h5>
                                {cls_.affinities.length > 0 && <h6>Affinities: {cls_.affinities.join(', ')}</h6>}
                                {cls_.skills.length > 0 && <h6>Skills: {cls_.skills.join(', ')}</h6>}
                                {cls_.effects && cls_.effects.length > 0 && (
                                    <div className="card-effects">
                                        {cls_.effects.map((effect, index) => (
                                            <h6 key={index}><span className="effect">Effect: </span>{`${effect.text}`}</h6>
                                        ))}
                                    </div>
                                )}
                                {cls_.actions && cls_.actions.length > 0 && (
                                    <h6>Class Actions: {cls_.actions.map(action => action.name).join(', ')}</h6>
                                )}
                            </div>
                        ))}

                        {(buildData.text || (buildData.affinities && buildData.affinities.length > 0) || (buildData.skills && buildData.skills.length ) || (buildData.effects && buildData.effects.length > 0) || (default_action && default_action.text)) &&
                            <div className="card-details">
                                {buildData.text && <h6 className="card-text">{buildData.text}</h6>}
                                {buildData.affinities && buildData.affinities.length > 0 && <h6>Affinities: {buildData.affinities.join(', ')}</h6>}
                                {buildData.skills && buildData.skills.length > 0 && <h6>Skills: {buildData.skills.join(', ')}</h6>}
                                {buildData.movement && ((buildData.piece_type == 'Aide' && buildData.movement[1] != 1) || (buildData.piece_type == 'Champion' && buildData.movement[1] != 3))  && <h6>Movement: {buildData.movement[1]}</h6>}
                                {buildData.effects && buildData.effects.length > 0 && (
                                    <div className="card-effects">
                                        {buildData.effects.map((effect, index) => (
                                            <h6 key={index}><span className="effect">Effect: </span>{`${effect.text}`}</h6>
                                        ))}
                                    </div>
                                )}
                                {default_action && !["Move", "No Action"].includes(default_action.name) && <h6>Action: {default_action.text}</h6>}
                                {buildData.actions && buildData.actions.filter(x => !["Move", "Hit"].includes(x.name)).length > 0 && buildData.piece_type == 'Aide' && (
                                    <h6>Additional Actions: {buildData.actions.filter(x => !["Move", "Hit"].includes(x.name)).map((action, index) => action.name).join(', ')}</h6>
                                )}
                            </div>
                        }
                        {((buildData.requirements && Object.keys(buildData.requirements).length > 0) || (!buildData.piece_type && buildData.proficiencies && Object.keys(buildData.proficiencies).length)) ?
                        (<div className='card-details xyz'>
                            {buildData.requirements && Object.keys(buildData.requirements).length > 0 && <h6>Requirements:</h6>}
                            {buildData.requirements && Object.entries(buildData.requirements).map(([requirement, num], index) => <h6 key={index}>{`${requirement}: ${num}`}</h6>)}
                            {!buildData.piece_type && buildData.proficiencies && Object.keys(buildData.proficiencies).length > 0 && <h6>Proficiencies:</h6>}
                            {!buildData.piece_type && buildData.proficiencies && Object.entries(buildData.proficiencies).map(([proficiency, num], index) => <h6 key={index}>{`${proficiency}: ${num}`}</h6>)}
                        </div>) : <></>}
                    </div>

                    {piece && ((piece.affinities && piece.affinities.length > 0) || (piece.effects && piece.effects.length > 0) || (piece.skills && piece.skills.length > 0) || (piece.default_action && !["Move", "No Action"].includes(piece.default_action.name))) && (
                        <div className="aide-details">
                            <div className="card-details">
                                {piece.affinities && piece.affinities.length > 0 && <h6>Affinities: {piece.affinities.join(', ')}</h6>}
                                {piece.skills && piece.skills.length > 0 && <h6>Skills: {piece.skills.join(', ')}</h6>}
                                {piece.movement && piece.movement[1] != 1 && <h6>Movement: {piece.movement[1]}</h6>}
                                {piece.effects && piece.effects.length > 0 && (
                                    <div className="card-effects">
                                        {piece.effects.map((effect, index) => (
                                            <h6 key={index}><span className="effect">Effect: </span>{`${effect.text}`}</h6>
                                        ))}
                                    </div>
                                )}
                                {piece.default_action && !["Move", "No Action"].includes(piece.default_action.name) && <h6>Action: {piece.default_action.text}</h6>}
                                {piece.actions && piece.actions.length > 0 && (
                                    <h6>Additional Actions: {piece.actions.map((action, index) => action.name).join(', ')}</h6>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {buildData.piece_type && (
                    <div className='champ-stats'>
                        <ChampionStat player={{ champion: buildData }} />
                    </div>
                )}
                {piece && piece.piece_type && (
                    <div className='champ-stats'>
                        <ChampionStat player={{ champion: piece }} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PieceCard;