import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const AuthWrapper = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !isRedirecting) {
        localStorage.setItem('returnTo', window.location.pathname);
        console.log('Triggering loginWithRedirect from ', `${window.location.origin}`, 'to', window.location.pathname);
        setIsRedirecting(true);
        loginWithRedirect({ 
        authorizationParams: {
            redirect_uri: window.location.origin
        },
    });
    }
  }, [isAuthenticated, isLoading, isRedirecting, loginWithRedirect]);

  useEffect(() => {
    const returnTo = localStorage.getItem('returnTo');
    if (returnTo && isAuthenticated) {
      localStorage.removeItem('returnTo');
      setIsRedirecting(false);
      console.log('Navigating to', returnTo);
      navigate(returnTo, { replace: true });
    }
  }, [isAuthenticated, isLoading, isRedirecting, navigate]);

  if (isLoading || isRedirecting) {
    return <div>Loading...</div>; // Render a loading state while redirecting
  }

  if (!isAuthenticated) {
    return null; // Render nothing if still not authenticated
  }

  return children;
};

export default AuthWrapper;
