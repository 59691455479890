import React, { useState } from 'react';
import './action-search-bar.css';

import { PieceCardHeader } from '../../../game/pieces/cards/piece-card/piece-card';

const ActionSearchBar = ({ allActions, currentActions, setCurrentActions, setHoveredAction, addAction }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAffinity, setSelectedAffinity] = useState('');
  const [selectedSkill, setSelectedSkill] = useState('');

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    filterActions(value, selectedAffinity, selectedSkill);
  };

  const handleAffinityChange = (e) => {
    const value = e.target.value;
    setSelectedAffinity(value);
    filterActions(searchTerm, value, selectedSkill);
  };

  const handleSkillChange = (e) => {
    const value = e.target.value;
    setSelectedSkill(value);
    filterActions(searchTerm, selectedAffinity, value);
  };

  const filterActions = (searchTerm, affinity, skill) => {
    let filteredActions = allActions.filter((action) => {
      const matchesSearchTerm = action.name.toLowerCase().includes(searchTerm);
      const matchesAffinity = affinity === '' || action.affinities.includes(affinity);
      const matchesSkill = skill === '' || action.skills.includes(skill);

      return matchesSearchTerm && matchesAffinity && matchesSkill;
    });

    setCurrentActions(filteredActions);
  };

  return (
    <div className="action-list-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <select onChange={handleAffinityChange} className="filter-select">
          <option value="">Filter by Affinity</option>
          {Array.from(new Set(allActions.flatMap(action => action.affinities)))
            .map((affinity, index) => (
              <option key={index} value={affinity}>{affinity}</option>
          ))}
        </select>
        <select onChange={handleSkillChange} className="filter-select">
          <option value="">Filter by Skill</option>
          {Array.from(new Set(allActions.flatMap(action => action.skills)))
            .map((skill, index) => (
              <option key={index} value={skill}>{skill}</option>
          ))}
        </select>
      </div>
      <ul className="action-list">
        {currentActions.map((action, index) => (
          <li key={index} className="action-item"
            onMouseEnter={() => setHoveredAction(action)}
            onMouseLeave={() => setHoveredAction(null)}
            onDoubleClick={() => addAction(action)}
          >
            <h4 className="action-name">{action.name}</h4>
            <p className="action-affinities">Affinities: {action.affinities.join(', ')}</p>
            <p className="action-skills">Skills: {action.skills.join(', ')}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActionSearchBar;
