import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { CiSaveDown1 } from "react-icons/ci";
import { useAuth0 } from "@auth0/auth0-react";
import './build-page.css';

import PieceCard, { PieceCardHeader } from "../../game/pieces/cards/piece-card/piece-card";
import { getPiece } from "../../game/pieces/champions";
import { getAllActions, getAllChampions, saveBuild } from "../../utils/getters";
import ActionSearchBar from "./action-search-bar/action-search-bar";
import BuildActionsList from "./build-actions-list/build-actions-list";


const getDefaultActions = (champion) => {
    if (!champion) { return []; }
    let actions = [];
    if (champion.actions) {
        actions = [...champion.actions];
    }

    if (champion.race.actions) {
        actions = [...actions, ...champion.race.actions];
    }

    for (let cls_ of champion.cls) {
        if (cls_.actions) {
            actions = [...actions, ...cls_.actions];
        }
    }
    return actions;
}

const BuildPage = () => {
    const [buildName, setBuildName] = useState('');
    const [champions, setChampions] = useState([]);
    const [allActions, setAllActions] = useState([]);
    const [currentActions, setCurrentActions] = useState([]);
    const [buildActions, setBuildActions] = useState([]);
    const [selectedChampionIndex, setSelectedChampionIndex] = useState(0);
    const [hoveredAction, setHoveredAction] = useState(null);
    const location = useLocation();
    let { getAccessTokenSilently } = useAuth0();

    const maxBuildSize = 8;

    const { input} = location.state || {};

    useEffect(() => {
        console.log('useEffect in build-page.js');
        getAllChampions().then(data => setChampions(data));
        getAllActions().then(data => setAllActions(data));
    }, []);

    useEffect(() => {
        if (!input) { return; }

        if (input.build_name) {
            setBuildName(input.build_name);
        }

        let champIndex = null;
        if (champions && input.name) {
            champIndex = champions.findIndex(champion => champion.name === input.name);
            setSelectedChampionIndex(champIndex);
        }

        if (allActions && input.actions) {
            console.log('input.actions', input.actions);
            console.log('getDefaultActions(champions[selectedChampionIndex])', getDefaultActions(champions[selectedChampionIndex]));
            setBuildActions(input.actions.map(action => allActions.find(a => a.name === action.name)).filter(a => a && (champIndex != null) && (getDefaultActions(champions[champIndex]).find(b => b.name === a.name) === undefined)));
        }

    }, [allActions]);

    const addAction = (action) => {
        if (buildActions.length >= maxBuildSize) { return; }

        if (buildActions.find(a => a.name === action.name)) { return; }

        if (selectedChampionIndex && getDefaultActions(champions[selectedChampionIndex]).find(a => a.name === action.name)) { return; }

        setBuildActions([...buildActions, action]);
    }



    const updateChampion = (index) => {
        setSelectedChampionIndex(index);
    }

    let champion = champions.length > 0 ? champions[selectedChampionIndex] : null;

    return (
    <div className='draft-page-build'>
        <div className='draft-champion'>
            <input 
                type="text" 
                placeholder="Build Name" 
                className="build-name-input" 
                value={buildName}
                onChange={(e)=>setBuildName(e.target.value)}
            />

            {champion && (hoveredAction ? <PieceCard buildData={hoveredAction} /> : <PieceCard buildData={getPiece(champion)} />)}
            <br />
            {champions.map((_champion, index) => 
            <div
                className={`build-champion-card ${index == selectedChampionIndex ? 'selected' : ''}`}
                onClick={()=>updateChampion(index)}
                onMouseEnter={()=>setHoveredAction(_champion)}
                onMouseLeave={()=>setHoveredAction(null)}
                key={index}
            >
                <PieceCardHeader buildData={getPiece(_champion)} />
            </div>
            )}
        </div>
        <div className="draft-actions">
            <BuildActionsList
                championActions={getDefaultActions(champion)}
                buildActions={buildActions}
                setBuildActions={setBuildActions} 
                setHoveredAction={setHoveredAction}
            />
        </div>
        <div className="draft-actions-backup">
            <ActionSearchBar 
                allActions={allActions}
                currentActions={currentActions}
                setCurrentActions={setCurrentActions}
                setHoveredAction={setHoveredAction}
                addAction={addAction}
            />
        </div>
        <div className="save-button">
            <CiSaveDown1 
                size={50}
                onClick={() => {
                    const submitData = {
                        build_name: buildName,
                        champion_name: champion.name,
                        actions: buildActions.map(action => action.name)
                    };
                    const submitBuild = async () => {
                        const token = await getAccessTokenSilently();
                        saveBuild(token, submitData);
                    }
                    submitBuild();
                    alert('Build saved!');
                }}
            />
        </div>
    </div>
    );
}

export default BuildPage;