let backend;
if (process.env.REACT_APP_DEV) {
    backend = 'http://127.0.0.1:5000'
} else {
    backend = 'https://api.solbrew.io'
}


export async function getAllBuilds() {
    try {
        const url = `${backend}/get_all_builds`;
        const response = await fetch(url, {
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching action:', error);
        return { error: error.message };
    }
};


export async function getAllActions() {
    try {
        const url = `${backend}/get_all_actions`;
        const response = await fetch(url, {
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching action:', error);
        return { error: error.message };
    }   
}

export async function getAllChampions() {
    try {
        const url = `${backend}/get_all_champions`;
        const response = await fetch(url, {
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching action:', error);
        return { error: error.message };
    }
}

export async function getUserBuilds(bearerToken) {
    const url = `${backend}/get_user_builds`;
    
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const builds = await response.json();
        return builds;
    } catch (error) {
        console.error('Error fetching user builds:', error);
        return null;
    }
}

export async function saveBuild(bearerToken, buildData) {
    const url = `${backend}/save_build`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(buildData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error saving build:', error);
    }
    return;
}
