import { useEffect, useState } from "react";
import ActionCard from "./pieces/cards/ActionCard";
import { useParams } from "react-router-dom";
import "./CardPage.css";
import PieceCard from "./pieces/cards/piece-card/piece-card";

// Define the base URL of the API
let backend;
if (process.env.REACT_APP_DEV) {
    backend = 'http://127.0.0.1:5000'
} else {
    backend = 'https://api.solbrew.io'
}
// Function to call the get_action API
async function getAction(actionName) {
    try {
        const url = `${backend}/get_action?action_name=${encodeURIComponent(actionName)}`;
        const response = await fetch(url, {
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Action data:', data);
        return data;

    } catch (error) {
        console.error('Error fetching action:', error);
        return { error: error.message };
    }
}

const CardPage = () => {
    const { card } = useParams();
    const [cardData, setCardData] = useState(null);

    useEffect(() => {
        if (!card || cardData) {
            return;
        }
        getAction(card).then(data => {
            if (data.error) {
                console.error('Error:', data.error);
            } else {
                console.log('Success:', data);
                setCardData(() => data);
            }
        });
    }, []);

    return (
        <>
            {cardData && (
                <div className="card-page">
                    <div className="card-page-card">
                        <PieceCard buildData={cardData} />
                    </div>
                </div>    
            )}
        </>
    );


};

export default CardPage;
