import React from 'react';
import Navbar from '../../common-components/navbar/navbar';
import ChampionPage from '../../game/pages/champions/ChampionPage';
// import Champions from './Champions';
import './home-page.css';

const HomePage = () => {
    return (
        <div className="home-page">
            <link href='https://fonts.googleapis.com/css?family=Bubblegum Sans' rel='stylesheet'></link>
            <Navbar />
            <div className="content">
                <section className="game-description">
                    <h1>Welcome to Solbrew!</h1>
                    <p>Solbrew is a game merging the exciting worlds, actions, and customization you have access to in games like DnD and MTG while also injecting chess like tactics.<br/>At a high level the game is turn-based, PvP, and takes place on a 6x6 grid. Each player has a champion which consists of a race, class, a set of stats, abilities, and actions they may make. You win when you reduce your opponent's champion's health to 0.</p>
                </section>
                <section className="example-champions">
                    <h2>Current Champions</h2>
                    <div className='example-champion-cards'>
                        <ChampionPage isGame={false} />
                    </div>
                </section>
            </div>
        </div>
    );
};

export default HomePage;
