import "./PieceImage.css";

import { FaHeart } from 'react-icons/fa';
import { GiFrozenOrb } from "react-icons/gi";
import { GiSwordSpade,GiShield } from 'react-icons/gi';
import { FiPackage } from "react-icons/fi";
import { GiPlainDagger } from "react-icons/gi";

import championStamp from '../../../assets/CHAMPION_FRAME.png';
import stamp from '../../../assets/STAMP.png';


const FillImage = ({ cell }) => {
    return (
        <img src={cell.image} alt={cell.name} className={`plainimage ${["Champion", "Aide"].includes(cell.piece_type) ? "fill":"no-fill"} champion-${cell.piece_type=="Champion"} aide-${cell.piece_type=="Aide"}`} />    );
};

const PieceImage = ({ cell, stats, flip }) => {
    return (
    <div className="piece">
        <div className={`statuses ${(cell.stuck > 0 || cell.was_stuck) || (cell.delay_counters && cell.delay_counters > 0) || cell.assassination_target ? 'show' : 'hidden'}`}>
            {(cell.stuck > 0 || cell.was_stuck) && (<div className="status stuck" ><GiFrozenOrb color='#31ccbc' />{cell.stuck > 0? cell.stuck: ''}</div>)}
            {(cell.delay_counters && cell.delay_counters > 0) && (<div className="status delay" ><FiPackage color='#31ccbc' />{cell.delay_counters}</div>)}
            {cell.assassination_target && (<div className="status delay" ><FiPackage color='#31ccbc' />{cell.delay_counters}</div>)}
        </div>
        {["Champion", "Aide"].includes(cell.piece_type) && (<img src={cell.piece_type == "Champion" ? championStamp : stamp} alt="championStamp" className={`image champion-${cell.piece_type=="Champion"} aide-${cell.piece_type=="Aide"} ${cell.owner_id === 0 ? (flip ?'me':'you') : (flip?'you':'me')}`} />)}
        <FillImage cell={cell} />
        {!!stats && (<div className='status resource-row'>
          {cell.piece_type && ['Aide', 'Champion'].includes(cell.piece_type) && <div className="stat str"><GiSwordSpade />{cell.strength}</div>}
          {!!cell.defense && <div className="stat def"><GiShield />{cell.defense}</div>}
          {!!cell.health && <div className="status health"><FaHeart color='red' />{cell.health[0]}</div>}
        </div>)}
    </div>
    );
};

export default PieceImage;