class Piece {
    owner;
  
    name;
    pending_damage;
    
    race;
    cls;

    health;
    mana;
    stamina;

    strength;
    intelligence;
    defense;

    isCls = true;
  
    constructor(props){
      if (props) {
        // if (props.name != "Empty") {
        //   console.log("cell props", props);
        // };
        // console.log("hi from y");
        Object.entries(props).forEach(([key, value]) => {
          // console.log(`${props.name} has ${key} of ${value}`)
          // console.log('check', key, value);
          this[key] = value;
        });
      }
    }
}
  
export default Piece;
  