import React from 'react';
import { Link } from 'react-router-dom';
import solbrewLogo from '../../assets/SOLBREW.png';
import { createGameClick, createDraftClick } from './navbar-utils';
import { useAuth0 } from '@auth0/auth0-react';

import './navbar.css';

const Navbar = () => {
    const { logout, user } = useAuth0();
    const username = user['solbrew.io/username'];

    return (
        <nav className="navbar">
            <div className="logo-container">
                <Link to="/">
                    <img src={solbrewLogo} alt="Solbrew Logo" className="logo" />
                </Link>
            </div>
            <ul className="nav-links">
                <li>
                    <div className="username">
                        {username}
                    </div>
                </li>
                {/* <li><Link className="nav-button" to="/profile">Profile</Link></li> */}
                {/* <li><Link className="nav-button" to="/">Games</Link></li> */}
                <li><Link className="nav-button" to="/build_maker">Build Maker</Link></li>
                <li><Link className="nav-button" to="/Builds">Builds</Link></li>
                <li>
                    <button className="nav-button"
                        onClick={createGameClick}
                    >
                        Create Game
                    </button>
                </li>
                <li>
                    <button className="nav-button"
                        onClick={createDraftClick}
                    >
                        Create Draft
                    </button>
                </li>
                <li>
                    <button className="nav-button"
                        onClick={logout}
                    >
                        Sign Out
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
