import React, { useEffect, useRef, useState } from 'react';
import './Grid.css';
import { Phases } from './types';
import PieceImage from './pieces/utils/PieceImage';
import { FaBoltLightning } from "react-icons/fa6";
import { GiLeatherBoot } from 'react-icons/gi';
import { HiArrowsExpand } from "react-icons/hi";
import LineTo from 'react-lineto';
import Xarrow from "react-xarrows";
import { actionToCursor } from './util/action-to-cursor';
import { TiArrowRightThick } from 'react-icons/ti';


const Grid = ({ playerId, gameId, gameState, setGameState, currentAction, setCurrentAction, targets, setTargets, socket, flip, canAct, resetVariables, hoveredTile, setHoveredTile }) => {
  let { turn_idx, turn_phase, players, grid, log } = gameState['state'];
  const gridRef = useRef(null);
  const [arrowCoords, setArrowCoords] = useState(null);

  const clickIcon = actionToCursor(currentAction);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Track mouse movement and update cursor position
  useEffect(() => {
    const updateMousePosition = (e) => {
      const { left, top } = gridRef.current.getBoundingClientRect();
      setPosition({ x: e.clientX - left, y: e.clientY - top });
    };
    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  const coord_to_index = (coord) => {
    return true ? coord : [grid.length - coord[0] - 1, grid[0].length - coord[1] - 1];
  };

  useEffect(() => {
    if (turn_phase === Phases.REACTION && log.length > 0) {
      const lastLog = log[log.length - 1];
      if (lastLog.target_position) {
        setArrowCoords([coord_to_index(lastLog.source_position), coord_to_index(lastLog.target_position)]);
      } else {
        setArrowCoords([coord_to_index(lastLog.source_position)]);
      }
    } else {
      setArrowCoords(null);
    }
  }, [turn_phase, log]);

  const getClickFn = (position, cell) => {
    if (!canAct) {
      // return () => {};
      return () => setCurrentAction({sourcePosition: position});
    }
    if (targets.length > 0) {
      // console.log("here~");
      if (JSON.stringify(targets).includes(JSON.stringify(position))) {
        return () => {
          let payload = { gameId, playerId, actions: [`${currentAction.name} ${currentAction.sourcePosition} ${position}`] };
          if (currentAction && currentAction.previous_targets) {
            payload.previous_targets = currentAction.previous_targets;
          };
          socket.current.emit('submit', payload);
          resetVariables();
        };
      } else {
        return () => {
          console.log('Invalid target', targets, position);
          resetVariables();
        };
      }
    };

    if (cell.owner_id !== players[turn_idx].id) {

    }
    
    if (turn_phase !== Phases.ACTION || !cell.default_action || cell.owner_id !== players[turn_idx].id) {
      return () => setCurrentAction({sourcePosition: position});
    }
    return () => {
      setCurrentAction({ ...cell.default_action, sourcePosition: position });
      setHoveredTile(null);
    };
  };

  const isTargeted = (i, j) => {
    return targets.some(target => target[0] === i && target[1] === j);
  };

  const numberToLetter = (num) => {
    return String.fromCharCode(65 + num);
  }

  const isSelected = (i, j) => {
    return currentAction && currentAction.sourcePosition[0] === i && currentAction.sourcePosition[1] === j;
  }

  const createGrid = () => {
    grid = flip ? grid : grid.map(row => row.slice(0).reverse()).slice(0).reverse();
    return (
      <>
        <div className={`grid-container ${clickIcon ? 'custom-on' : 'custom-off'}`} ref={gridRef}>
          <div
            className={`custom-cursor`}
            style={{
              // left: '0',
              // top: '0',
              left: `${position.x}px`,
              top: `${position.y}px`,
            }}
          >
            {clickIcon}
            {/* <TiArrowRightThick size={24} /> */}
          </div>
          {grid.map((row, rowIndex) => flip ? [row, rowIndex] : [row, row.length - 1 - rowIndex]).map(([row, rowIndex]) => (
            <div className="row" key={rowIndex}>
              <div className="row-number">{grid.length - rowIndex}</div>
              {row.map((cell, colIndex) => flip ? [cell, colIndex] : [cell, row.length - 1 - colIndex]).map(([cell, colIndex]) => (
                <div 
                  className={`tile row-idx-${rowIndex} col-idx-${colIndex} row-${rowIndex % 2} col-${colIndex % 2} ${isTargeted(rowIndex, colIndex) ? 'highlighted' : ''} ${isSelected(rowIndex, colIndex) ? 'selected' : ''} ${cell.owner_id === playerId ? 'yours' : (cell.owner_id === 1 - playerId ? 'theirs' : '')}`}
                  key={`${rowIndex}-${colIndex}`} 
                  data-position={`${rowIndex}-${colIndex}`}
                  id={`${rowIndex}-${colIndex}`}
                  onClick={getClickFn([rowIndex, colIndex], cell)}
                  onMouseEnter={() => {
                    if (!isSelected(rowIndex, colIndex) && (cell.piece_type === 'Champion' || cell.piece_type === 'Aide' || cell.piece_type === 'Plot' || cell.piece_type === 'Trap')) {
                      setHoveredTile(cell);
                    }
                  }}
                  onMouseLeave={() => setHoveredTile(null)}
                >
                  {canAct && cell.owner_id === turn_idx && cell.default_action && cell.default_action.name !== 'No Action' && !cell.acted && <div className="overlay acted"><FaBoltLightning /></div>}
                  {canAct && cell.owner_id === turn_idx && cell.movement && cell.movement[0] > 0 && <div className="overlay movement"><GiLeatherBoot /></div>}
                  {cell.name !== 'Empty' && (
                    <div className={`piece ${!!cell.visibility ? cell.visibility : 'Visible'}`}>
                      {cell.health && (<PieceImage cell={cell} flip={flip} stats={true} />)}
                    </div>
                  )}
                  {arrowCoords && arrowCoords.length == 1 && arrowCoords[0][0] == rowIndex && arrowCoords[0][1] == colIndex && (
                    <HiArrowsExpand style={{zIndex:10, color: 'red', position: 'absolute', backgroundColor: '#00000088', borderRadius: '10px'}} size={50} />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="col-numbers">
          {[...Array(grid[0].length).keys()].map(colIndex => (
            <div className="col-number" key={colIndex}>{`${numberToLetter(flip ? colIndex : grid[0].length - 1 - colIndex)}`}</div>
          ))}
        </div>
        {/* {arrowCoords && <LineTo from={`tile row-idx-${arrowCoords[0][0]} col-idx-${arrowCoords[0][1]}`} to={`tile row-idx-${arrowCoords[1][0]} col-idx-${arrowCoords[1][1]}`} zIndex={10} borderWidth={"5px"}/>} */}
        {arrowCoords && arrowCoords.length > 1 && <Xarrow
          start={`${arrowCoords[0][0]}-${arrowCoords[0][1]}`} //can be react ref
          end={`${arrowCoords[1][0]}-${arrowCoords[1][1]}`} //or an id
          startAnchor={"end"}
          endAnchor={"middle"}
          zIndex={10}
          color='red'
          animateDrawing={.4}
        />}
        {/* <CircleArrows /> */}
      </>
    );
  };


  return (
    <div className="grid">
      {createGrid()}
    </div>
  );
};


const CircleArrows = () => {
  const startId = 'start';
  const endId = 'end';
  const numArrows = 4; // Number of arrows in the circle
  const radius = 100; // Radius of the circle

  const arrows = [];
  for (let i = 0; i < numArrows; i++) {
    const angle = (i * 360) / numArrows;
    const xOffset = radius * Math.cos((angle * Math.PI) / 180);
    const yOffset = radius * Math.sin((angle * Math.PI) / 180);
    const del = -250;

    arrows.push(
      <Xarrow
        key={i}
        start={startId}
        end={endId}
        startAnchor={{ position: 'middle', offset: { x: del+xOffset, y: del+yOffset } }}
        endAnchor={{ position: 'middle', offset: { x: del-xOffset, y: del-yOffset } }}
        // path="straight"
        showHead={false} // Optional: Remove arrowhead
        color="blue"
      />
    );
  }

  return (
    <div style={{ position: 'relative', width: '200px', height: '200px' }}>
      <div
        id={startId}
        style={{
          width: '10px',
          height: '10px',
          backgroundColor: 'red',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      ></div>
      <div
        id={endId}
        style={{
          width: '10px',
          height: '10px',
          backgroundColor: 'green',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      ></div>
      {arrows}
    </div>
  );
};


export default Grid;
